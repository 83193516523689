.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.el-form /deep/ .el-form-item {
  width: 520px;
}
.el-form /deep/ .el-form-item .el-select {
  width: 100%;
}
.learn_item {
  margin-bottom: 1.0416666666666665vw;
}
